<template>
  <el-row :gutter="10">

    <el-col
      v-for="n in scaleConfig.varsAmount"
      :key="n"
      class="card-view-number-multiple-col"
      :span="scaleConfig.viewSpan"
    >
      <div><b>Цель {{n}}</b>: {{ scale.targets ? scale.targets['col'+n] : null }}{{ (scaleConfig.colsConfig && scaleConfig.colsConfig['col'+n] && scaleConfig.colsConfig['col'+n].percentFrom) ? '%' : '' }}</div>
    </el-col>

  </el-row>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "card-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        targets: {},
        pays: {},
      },
    }
  },
  mounted() {

  },
  computed: {},
  methods: {}
}
</script>

<style>
</style>